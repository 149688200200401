import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, ref, onMounted, onUnmounted, computed, getCurrentInstance, nextTick, isReactive } from "vue";
import SvgIcon from "../components/SvgIcon.vue";
import { dateTableEmits } from "element-plus/es/components/calendar/src/date-table";
import util from "@/util/util";
import permission from "@/util/permission";
import ExDialog from "../components/ExDialog.vue";
import ResizePane from "../components/ResizePane.vue";

import { ElNotification, ElMessageBox } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
import 'element-plus/es/components/message-box/style/css';
export default defineComponent({
  name: "UserManagement",
  components: {
    SvgIcon,
    ExDialog,
    ResizePane
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const tokenData = util.getToken();
    const userTable = ref(null);
    const getOrganization = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.organizationRequest.getOrganization(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getModule = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.moduleRequest.getModule(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const organizationDialog = ref(null);
    const organizationTree = ref(null);
    const organizationNameInput = ref(null);
    const organizationDialogVisible = ref(false);
    const organizationForm = ref(null);
    const organizationFormData = ref({});
    const organizationFormRules = reactive({
      organizationName: [{
        required: true,
        message: "名称必须填写",
        trigger: "blur"
      }]
    });
    const itemRender = current => {
      return current.originalElement;
    };
    var currentOrganization = null;
    const organizationData = reactive([]);
    const organizationDept = {};
    const expandKeys = reactive([1, 2, 3]);
    const currentKey = ref(1);
    const searchKeyword = ref(null);
    if (tokenData.type == 2) {
      expandKeys.length = 0;
      expandKeys.push(2);
      currentKey.value = 2;
    } else if (tokenData.type == 3) {
      expandKeys.length = 0;
      expandKeys.push(3);
      currentKey.value = 3;
    }
    const moduleData = reactive([]);
    const moduleEditData = reactive({
      managerId: null,
      type: null,
      data: []
    });
    const operateStatus = ref({
      isNew: false,
      organizationDialogTitle: null,
      userDialogTitle: null,
      permissionDialogTitle: null
    });
    const userControl = reactive({
      addButtonDisable: false,
      editButtonDisable: false,
      deleteButtonDisable: false
    });
    const userLoading = ref(false);
    const userDialog = ref(null);
    const userDialogVisible = ref(false);
    const userForm = ref(null);
    const userFormData = ref({
      id: null,
      lastName: null,
      firstName: null,
      mobile: null,
      email: null,
      type: null,
      managerId: null,
      remark: null
    });
    const userFormRules = reactive({
      lastName: [{
        required: true,
        message: "请输入姓氏",
        trigger: "blur"
      }],
      firstName: [{
        required: true,
        message: "请输入名字",
        trigger: "blur"
      }],
      mobile: [{
        required: true,
        message: "请输入手机号码",
        trigger: "blur"
      }, {
        min: 11,
        max: 11,
        message: "请输入正确的手机号码",
        trigger: "blur"
      }],
      email: [{
        required: true,
        message: "请输入邮箱",
        trigger: "blur"
      }, {
        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        message: "请输入有效的邮箱",
        trigger: "blur"
      }]
    });
    const permissionDialog = ref(null);
    const permissionDialogVisible = ref(false);
    const organizationPopoverVisible = ref(false);
    const leftBarWidth = ref("");
    const contentLeftBarSizeChange = size => {
      var width = window.innerWidth;
      if (width > 400) {
        leftBarWidth.value = size.width - 8 + "px";
      } else {
        leftBarWidth.value = 382 + "px";
      }
    };
    onMounted(async () => {
      let module = await getModule();
      if (module == null) {
        return;
      }
      moduleData.push(...module);
      var organizationList = await getOrganization();
      if (organizationList == null) {
        return;
      }
      organizationData.length = 0;
      let lastOrganizationData = null;
      for (var organization of organizationList) {
        if (organization.type === 3) {
          //此处用于不显示店铺相关数据
          continue;
        }
        lastOrganizationData = organization;
        if (organization.depth == 1) {
          organizationDept[organization.path + organization.organizationId] = organization;
          organizationData.push(organization);
        } else {
          if (organizationDept[organization.path].children == null) {
            organizationDept[organization.path].children = [];
          }
          organizationDept[organization.path + "/" + organization.organizationId] = organization;
          organizationDept[organization.path].children.push(organization);
        }
      }
      if (tokenData.type == 1) {
        await nextTick();
        console.log(organizationData[0].organizationId);
        organizationTree.value.setCurrentKey(organizationData[0].organizationId, true);
        // currentChange(organizationData[0]);
      } else {
        await nextTick();
        console.log(lastOrganizationData.organizationId);
        organizationTree.value.setCurrentKey(lastOrganizationData.organizationId, true);
        // currentChange(lastOrganizationData);
      }
    });

    const props = {
      value: "organizationId",
      label: "organizationName",
      children: "children"
    };
    const currentChange = async data => {
      console.log(data);
      currentOrganization = data;
      currentSelectType.value = data.type;
      paginationData.currentPage = 1;
      if (currentSelectType.value == 1) {
        userControl.addButtonDisable = false;
        userControl.editButtonDisable = false;
        userControl.deleteButtonDisable = false;
        brandUserData.query.organizationId = data.organizationId;
      } else if (currentSelectType.value == 2) {
        userControl.addButtonDisable = true;
        userControl.editButtonDisable = true;
        userControl.deleteButtonDisable = true;
        vendorUserData.query.vendorTypeId = data.relatedId;
      } else if (currentSelectType.value == 3) {
        userControl.addButtonDisable = true;
        userControl.editButtonDisable = true;
        userControl.deleteButtonDisable = true;
        storeUserData.query.storeTypeId = data.relatedId;
      } else if (currentSelectType.value == 4) {
        userControl.addButtonDisable = true;
        userControl.editButtonDisable = true;
        userControl.deleteButtonDisable = true;
        dealerUserData.query.relatedId = data.relatedId;
      } else if (currentSelectType.value == 5) {
        userControl.addButtonDisable = true;
        userControl.editButtonDisable = true;
        userControl.deleteButtonDisable = true;
        dealerUserData.query.relatedId = data.relatedId;
      }
      userLoad();
    };
    const userLoad = () => {
      userData.data = [];
      userLoading.value = true;
      if (currentSelectType.value == 1) {
        const userPageRO = {
          managerId: brandUserData.query.organizationId,
          type: 1,
          searchKeyword: searchKeyword.value
        };
        getUserRequest(paginationData.currentPage, paginationData.pageSize, userPageRO);
      } else if (currentSelectType.value == 2) {
        const vendorUserPageRO = {
          vendorTypeId: vendorUserData.query.vendorTypeId,
          searchKeyword: searchKeyword.value
        };
        getVendorUserPageRequest(paginationData.currentPage, paginationData.pageSize, vendorUserPageRO);
      } else if (currentSelectType.value == 3) {
        const storeUserPageRO = {
          storeTypeId: storeUserData.query.storeTypeId,
          searchKeyword: searchKeyword.value
        };
        getStoreUserRequest(paginationData.currentPage, paginationData.pageSize, storeUserPageRO);
      } else if (currentSelectType.value == 4) {
        const userPageRO = {
          managerId: dealerUserData.query.relatedId,
          type: 4,
          searchKeyword: searchKeyword.value
        };
        getUserRequest(paginationData.currentPage, paginationData.pageSize, userPageRO);
      }
    };
    var lastParentOrganization = null;
    const removeOrganizationById = (prop, obj, id, parent) => {
      if (Array.isArray(obj)) {
        for (let i = obj.length - 1; i >= 0; i--) {
          if (obj[i][prop] === id) {
            lastParentOrganization = parent;
            obj.splice(i, 1);
          } else {
            removeOrganizationById(prop, obj[i].children, id, obj[i]);
          }
        }
      }
    };
    const addOrganization = async () => {
      operateStatus.value.organizationDialogTitle = "新增类别";
      operateStatus.value.isNew = true;
      if (currentOrganization.children == null) {
        currentOrganization.children = [];
      }
      var newOrganization = {
        organizationId: 0,
        organizationName: null,
        type: 1,
        path: currentOrganization.path + (currentOrganization.path == "/" ? "" : "/") + currentOrganization.organizationId
      };
      currentOrganization.children.push(newOrganization);
      organizationFormData.value = JSON.parse(JSON.stringify(newOrganization));
      await nextTick();
      organizationTree.value.setCurrentKey(0, true);
      organizationDialogVisible.value = true;
      // organizationNameInput.value.focus();
    };

    const editOrganization = () => {
      operateStatus.value.organizationDialogTitle = "编辑";
      operateStatus.value.isNew = false;
      organizationFormData.value = JSON.parse(JSON.stringify(currentOrganization));
      organizationDialogVisible.value = true;
      // organizationNameInput.value.focus();
    };

    const deleteOrganization = () => {
      if (currentOrganization == null) {
        ElNotification({
          title: "提示",
          message: "没有选中记录",
          type: "error",
          duration: 3000
        });
        return;
      }
      ElMessageBox.confirm("是否要删除" + currentOrganization.organizationName, "询问", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        deleteOrganizationRequest(currentOrganization);
      }).catch(error => {
        console.log(error);
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    const updatePermissions = async type => {
      var permissionRO = {
        type: moduleEditData.type,
        managerId: moduleEditData.managerId
      };
      var permissions = await getPermissionByManager(permissionRO);
      moduleEditData.data.length = 0;
      for (module of moduleData) {
        let moduleItem = JSON.parse(JSON.stringify(module));
        if (Array.isArray(moduleItem.modulePermissions)) {
          for (let i = 0; i < moduleItem.modulePermissions.length; i++) {
            let moduleSub = moduleItem.modulePermissions[i];
            if (moduleSub.modulePermissionOrganizationType.indexOf("" + type) != -1 && (currentUserPrimary == 1 || !(currentUserPrimary == 2 && module.moduleKey == "用户管理" && moduleSub.modulePermissionKey == "权限管理"))) {
              let findPermission = permissions.find(item => item.modulePermissionId === moduleSub.modulePermissionId);
              if (findPermission != null) {
                moduleSub.checked = true;
              } else {
                moduleSub.checked = false;
              }
            } else {
              moduleItem.modulePermissions.splice(i, 1);
              i--;
            }
          }
        }
        if (moduleItem.modulePermissions != null && moduleItem.modulePermissions.length > 0) {
          moduleEditData.data.push(moduleItem);
        }
      }
      operateStatus.value.permissionDialogTitle = "团队权限";
      permissionDialogVisible.value = true;
    };
    const updateTeamPermissions = () => {
      if (currentOrganization == null) {
        ElNotification({
          title: "提示",
          message: "没有选中记录",
          type: "error",
          duration: 3000
        });
        return;
      }
      moduleEditData.managerId = currentOrganization.organizationId;
      moduleEditData.type = 1;
      updatePermissions(currentSelectType.value);
    };
    const updateOrganizationRequest = async organizationUpdateRO => {
      proxy.$api.organizationRequest.updateOrganization(organizationUpdateRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202052") {
            currentOrganization.organizationName = res.result.data.organizationName;
            organizationDialogVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    const addOrganizationRequest = async organizationAddRO => {
      proxy.$api.organizationRequest.addOrganization(organizationAddRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202051") {
            util.propertyClone(res.result.data, currentOrganization);
            organizationDialogVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const deleteOrganizationRequest = async organizationDeleteRO => {
      proxy.$api.organizationRequest.deleteOrganization(organizationDeleteRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202053") {
            removeOrganizationById("organizationId", organizationData, res.result.data.organizationId);
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const cancelSaveOrganization = async () => {
      if (operateStatus.value.isNew == true) {
        lastParentOrganization = null;
        removeOrganizationById("organizationId", organizationData, 0);
        if (lastParentOrganization != null) {
          await nextTick();
          organizationTree.value.setCurrentKey(lastParentOrganization.organizationId, true);
        }
        operateStatus.value.isNew = false;
      }
      organizationForm.value.resetFields();
      organizationDialogVisible.value = false;
    };
    const confirmSaveOrganization = async () => {
      try {
        await organizationForm.value.validate();
        if (operateStatus.value.isNew === true) {
          await addOrganizationRequest(organizationFormData.value);
        } else {
          await updateOrganizationRequest(organizationFormData.value);
        }
      } catch (fields) {
        // 处理验证失败的情况
        console.error("Validation failed:", fields);
      }
    };
    const paginationSizeChange = val => {
      // if (currentSelectType.value == 3) {
      //   const storeUserPageRO = {
      //     storeTypeId: storeUserData.query.storeTypeId,
      //   };
      //   getStoreUserRequest(
      //     paginationData.currentPage,
      //     paginationData.pageSize,
      //     storeUserPageRO
      //   );
      // }
      // console.log(`${val} items per page`);
      userData.data = [];
      userLoad();
    };
    const paginationCurrentChange = val => {
      // if (currentSelectType.value == 3) {
      //   const storeUserPageRO = {
      //     storeTypeId: storeUserData.query.storeTypeId,
      //   };
      //   getStoreUserRequest(
      //     paginationData.currentPage,
      //     paginationData.pageSize,
      //     storeUserPageRO
      //   );
      // }
      // console.log(`current page: ${val}`);
      userData.data = [];
      userLoad();
    };
    const paginationData = reactive({
      pageSizes: [20, 40, 80, 100],
      currentPage: 1,
      totalSize: 0,
      pageSize: 20
    });
    const currentSelectType = ref();
    let currentUserPrimary = 1;
    const userData = reactive({
      data: []
    });
    //店铺用户

    const storeUserData = reactive({
      data: [],
      query: {
        storeTypeId: null
      }
    });
    const getStoreUserRequest = async (index, pageSize, storeUserPageRO) => {
      let storeUserPageROPageInfo = {
        pageSize: pageSize,
        pageIndex: index,
        data: storeUserPageRO
      };
      proxy.$api.storeRequest.getStoreUser(storeUserPageROPageInfo).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202100") {
            storeUserData.data = res.result.data.pageList;
            paginationData.totalSize = res.result.data.totalCount;
            if (storeUserData.data != null) {
              for (let item of storeUserData.data) {
                userData.data.push({
                  id: item.storeId + "",
                  name: item.storeName,
                  mobile: item.contactMobile,
                  email: item.contactEmail,
                  stateName: item.storeStatusNameZh,
                  primary: null,
                  parentId: null,
                  type: 3
                });
                if (item.users != null) {
                  for (let subItem of item.users) {
                    userData.data.push({
                      id: item.storeId + "/" + subItem.id,
                      name: subItem.lastName + subItem.firstName,
                      mobile: subItem.mobile,
                      email: subItem.email,
                      stateId: subItem.stateId,
                      stateName: subItem.stateName,
                      primary: subItem.primaryAccount,
                      parentId: item.storeId,
                      remark: subItem.remark,
                      isUser: 1,
                      userId: subItem.id,
                      type: 3,
                      lastName: subItem.lastName,
                      firstName: subItem.firstName
                    });
                  }
                }
              }
              nextTick(() => {
                if (userTable && userTable.value) {
                  userTable.value.setAllTreeExpand(true);
                }
              });
            }
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      });
    };

    //供应商用户
    const vendorUserData = reactive({
      data: [],
      query: {
        vendorTypeId: null
      }
    });
    const getVendorUserPageRequest = async (index, pageSize, vendorUserPageRO) => {
      let vendorUserPageROPageInfo = {
        pageSize: pageSize,
        pageIndex: index,
        data: vendorUserPageRO
      };
      proxy.$api.vendorRequest.getVendorUserPage(vendorUserPageROPageInfo).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202150") {
            vendorUserData.data = res.result.data.pageList;
            paginationData.totalSize = res.result.data.totalCount;
            if (vendorUserData.data != null) {
              for (let item of vendorUserData.data) {
                userData.data.push({
                  id: item.vendorId + "",
                  name: item.vendorName,
                  mobile: item.contactMobile,
                  email: item.contactEmail,
                  stateName: item.vendorStatusNameZh,
                  primary: null,
                  parentId: null,
                  type: 2
                });
                if (item.users != null) {
                  for (let subItem of item.users) {
                    userData.data.push({
                      id: item.vendorId + "/" + subItem.id,
                      name: subItem.lastName + " " + subItem.firstName,
                      mobile: subItem.mobile,
                      email: subItem.email,
                      stateId: subItem.stateId,
                      stateName: subItem.stateName,
                      primary: subItem.primaryAccount,
                      parentId: item.vendorId,
                      remark: subItem.remark,
                      isUser: 1,
                      userId: subItem.id,
                      type: 2,
                      lastName: subItem.lastName,
                      firstName: subItem.firstName
                    });
                  }
                }
              }
              nextTick(() => {
                if (userTable && userTable.value) {
                  userTable.value.setAllTreeExpand(true);
                }
              });
            }
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      });
    };

    //品牌用户
    const brandUserData = reactive({
      data: [],
      query: {
        organizationId: null
      }
    });
    //经销商用户
    const dealerUserData = reactive({
      data: [],
      query: {
        relatedId: null
      }
    });
    const getUserRequest = async (index, pageSize, userPageRO) => {
      let userPageROPageInfo = {
        pageSize: pageSize,
        pageIndex: index,
        data: userPageRO
      };
      proxy.$api.userRequest.getUserPage(userPageROPageInfo).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202000") {
            brandUserData.data = res.result.data.pageList;
            paginationData.totalSize = res.result.data.totalCount;
            if (brandUserData.data != null) {
              for (let item of brandUserData.data) {
                userData.data.push({
                  id: item.id,
                  name: currentSelectType.value == 1 ? item.lastName + " " + item.firstName : item.username,
                  mobile: item.mobile,
                  email: item.email,
                  stateId: item.stateId,
                  stateName: item.stateName,
                  primary: null,
                  parentId: null,
                  remark: item.remark,
                  isUser: 1,
                  userId: item.id,
                  type: item.type,
                  lastName: item.lastName,
                  firstName: item.firstName
                });
              }
              nextTick(() => {
                if (userTable && userTable.value) {
                  userTable.value.setAllTreeExpand(true);
                }
              });
            }
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      });
    };
    const updateUserRequest = async userUpdateRO => {
      proxy.$api.userRequest.updateUser(userUpdateRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202003") {
            currentRow.name = res.result.data.lastName + " " + res.result.data.firstName;
            currentRow.mobile = res.result.data.mobile;
            currentRow.email = res.result.data.email;
            currentRow.remark = res.result.data.remark;
            userDialogVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const addUserRequest = async userAddRO => {
      proxy.$api.userRequest.addUser(userAddRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202001") {
            userData.data.push({
              id: res.result.data.managerId + "/" + res.result.data.id,
              name: res.result.data.lastName + " " + res.result.data.firstName,
              mobile: res.result.data.mobile,
              email: res.result.data.email,
              stateId: res.result.data.stateId,
              stateName: res.result.data.stateName,
              primary: res.result.data.primaryAccount,
              parentId: res.result.data.managerId,
              remark: res.result.data.remark,
              isUser: 1,
              userId: res.result.data.id,
              type: res.result.data.type
            });
            userDialogVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const deleteUserRequest = async userDeleteRO => {
      proxy.$api.userRequest.deleteUser(userDeleteRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202005") {
            removeOrganizationById("id", userData.data, currentRow.id);
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    var currentRow = null;
    const confirmSaveUser = async () => {
      try {
        await userForm.value.validate();
        if (userFormData.value.id == null) {
          await addUserRequest(userFormData.value);
        } else {
          await updateUserRequest(userFormData.value);
        }
      } catch (fields) {
        // 处理验证失败的情况
        console.error("Validation failed:", fields);
      }
    };
    const cancelSaveUser = () => {
      userForm.value.resetFields();
      userDialogVisible.value = false;
    };
    const UserApproveClick = row => {
      approveUserRequest(row);
    };
    const approveUserRequest = async row => {
      proxy.$api.userRequest.approveUser(row.userId).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202014") {
            row.stateId = res.result.data.stateId;
            row.stateName = res.result.data.stateName;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const UserPermissionsClick = row => {
      currentUserPrimary = 1;
      if (row.isUser === 1) {
        moduleEditData.type = 5;
        moduleEditData.managerId = row.userId;
        currentUserPrimary = row.primary;
        updatePermissions(row.type);
      } else {
        moduleEditData.managerId = row.id;
        moduleEditData.type = row.type;
        updatePermissions(currentSelectType.value);
      }
    };
    const brandUserCreateClick = () => {
      if (currentOrganization == null) {
        ElNotification({
          title: "提示",
          message: "没有选中记录",
          type: "error",
          duration: 3000
        });
        return;
      }
      UserCreateClick({
        id: currentOrganization.organizationId,
        type: 1
      });
    };
    const UserCreateClick = row => {
      if (row.isUser == null) {
        console.log(row);
        operateStatus.value.userDialogTitle = "新建人员";
        userFormData.value.id = null;
        userFormData.value.lastName = null;
        userFormData.value.firstName = null;
        userFormData.value.mobile = null;
        userFormData.value.email = null;
        userFormData.value.type = row.type;
        userFormData.value.managerId = row.id;
        userFormData.value.remark = null;
        userDialogVisible.value = true;
        currentRow = row;
      }
    };
    const UserUpdateClick = row => {
      if (row.isUser == 1) {
        operateStatus.value.userDialogTitle = "修改账号";
        userFormData.value.id = row.type == 1 ? row.id : row.id.split("/")[1];
        userFormData.value.lastName = row.lastName;
        userFormData.value.firstName = row.firstName;
        userFormData.value.mobile = row.mobile;
        userFormData.value.email = row.email;
        userFormData.value.type = row.type;
        userFormData.value.managerId = row.parentId;
        userFormData.value.remark = row.remark;
        userDialogVisible.value = true;
        currentRow = row;
      }
    };
    const UserDeleteClick = row => {
      ElMessageBox.confirm("是否要删除" + row.name, "询问", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        currentRow = row;
        var userDeleteRO = {
          id: row.type == 1 ? row.id : row.id.split("/")[1]
        };
        deleteUserRequest(userDeleteRO);
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const getPermissionByManager = async permissionRO => {
      return new Promise((resolve, reject) => {
        proxy.$api.permissionRequest.getPermissionByManager(permissionRO).then(res => {
          if (res.code === "200000") {
            if (res.result.dataCode === "201035") {
              resolve(res.result.data);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "错误",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const savePermission = async permissionSaveRO => {
      proxy.$api.permissionRequest.savePermission(permissionSaveRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201037") {
            permissionDialogVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const confirmPermissionUser = () => {
      debugger;
      var permissionSaveRO = {
        type: moduleEditData.type,
        managerId: moduleEditData.managerId,
        modulePermissionIds: []
      };
      for (let module of moduleEditData.data) {
        for (let modulePermission of module.modulePermissions) {
          if (modulePermission.checked == true) {
            permissionSaveRO.modulePermissionIds.push(modulePermission.modulePermissionId);
          }
        }
      }
      savePermission(permissionSaveRO);
    };

    //
    const getRowClassName = row => {
      if (row.row.stateName === "启用" || row.row.stateName === "开店") {
        return "borderLeftOpen";
      } else if (row.row.stateName === "停用" || row.row.stateName === "闭店") {
        return "borderLeftClose";
      } else {
        return "borderLeftAwait";
      }
    };
    const userTableScroll = ({
      type,
      scrollTop,
      scrollLeft,
      scrollHeight,
      scrollWidth,
      bodyWidth,
      bodyHeight,
      isX,
      isY,
      $event
    }) => {
      let mediaQuery = window.matchMedia("(max-width: 768px)");
      if (mediaQuery.matches) {
        if (scrollTop + bodyHeight >= scrollHeight) {
          let totalCount = Math.ceil(paginationData.totalSize / paginationData.pageSize);
          if (paginationData.currentPage < totalCount) {
            if (currentSelectType.value == 3) {
              paginationData.currentPage++;
              userLoad();
            }
          }
        }
      }
    };
    return {
      searchKeyword,
      userLoad,
      currentUserPrimary,
      permission,
      userTableScroll,
      props,
      organizationDialog,
      organizationTree,
      expandKeys,
      currentKey,
      organizationNameInput,
      operateStatus,
      organizationData,
      userControl,
      userLoading,
      userDialog,
      userDialogVisible,
      organizationPopoverVisible,
      leftBarWidth,
      contentLeftBarSizeChange,
      currentChange,
      addOrganization,
      editOrganization,
      deleteOrganization,
      updateTeamPermissions,
      organizationDialogVisible,
      organizationForm,
      organizationFormData,
      organizationFormRules,
      updateOrganizationRequest,
      addOrganizationRequest,
      deleteOrganizationRequest,
      cancelSaveOrganization,
      confirmSaveOrganization,
      currentSelectType,
      paginationSizeChange,
      paginationCurrentChange,
      paginationData,
      userData,
      storeUserData,
      vendorUserData,
      brandUserData,
      userForm,
      userFormData,
      userFormRules,
      updateUserRequest,
      addUserRequest,
      deleteUserRequest,
      confirmSaveUser,
      cancelSaveUser,
      UserPermissionsClick,
      brandUserCreateClick,
      UserCreateClick,
      UserUpdateClick,
      UserDeleteClick,
      moduleData,
      moduleEditData,
      permissionDialog,
      permissionDialogVisible,
      confirmPermissionUser,
      UserApproveClick,
      userTable,
      itemRender,
      getRowClassName
    };
  }
});