/**
 * userRequest模块接口列表
 */

import axios from "@/util/http"; // 导入http中创建的axios实例
import qs from "qs";
import cookies from "js-cookie";
import util from "@/util/util";

let Base64 = require("js-base64").Base64;

const scheduleRequest = {
  getStoreInstallationPage(installationROPageInfo, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(
        `/api/vendor/getStoreInstallationPage`,
        installationROPageInfo,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //获取安装；列表
  getInstallationPage(installationROPageInfo, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/getInstallationPage`, installationROPageInfo, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getInstallationExport(installationPageRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      throw "token not have";
    }
    console.log("token = " + tokenJson.access_token);
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        "Content-Type": "application/json;charset=UTF-8",
      },
      responseType: "blob",
    };

    return axios
      .post(`/api/vendor/getInstallationExport`, installationPageRO, config)
      .then((response) => {
        var disposition = response.headers["content-disposition"];
        var fileName = decodeURI(
          disposition.substring(
            disposition.indexOf("filename=") + 9,
            disposition.length
          )
        );
        let blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //获取安装状态
  getInstallationStatus(retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/vendor/getInstallationStatus`, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //获取确认状态
  getInstallationConfirm(retry) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/getInstallationConfirm`, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getLogisticsExport(activityId) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      throw "token not have";
    }
    console.log("token = " + tokenJson.access_token);
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        "Content-Type": "application/json;charset=UTF-8",
      },
      responseType: "blob",
    };

    var param = { activityId: activityId };
    param = util.parseParams(param);

    return axios
      .get(`/api/vendor/getLogisticsExport?` + param, config)
      .then((response) => {
        var disposition = response.headers["content-disposition"];
        var fileName = decodeURI(
          disposition.substring(
            disposition.indexOf("filename=") + 9,
            disposition.length
          )
        );
        let blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getLogisticsListExport(logisticsPageRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      throw "token not have";
    }
    console.log("token = " + tokenJson.access_token);
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        "Content-Type": "application/json;charset=UTF-8",
      },
      responseType: "blob",
    };

    return axios
      .post(`/api/vendor/getLogisticsListExport`, logisticsPageRO, config)
      .then((response) => {
        var disposition = response.headers["content-disposition"];
        var fileName = decodeURI(
          disposition.substring(
            disposition.indexOf("filename=") + 9,
            disposition.length
          )
        );
        let blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //导入物流表
  importLogistics(uploadedRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/vendor/importLogistics`, uploadedRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //获取物流列表
  getLogisticsPage(logisticsPageRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/getLogisticsPage`, logisticsPageRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //获取物流状态
  getExpressState(retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/vendor/getExpressState`, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //获取物流类型
  getLogisticsType(retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/vendor/getLogisticsType`, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //获取物流详情
  queryLogistics(expressRO, retry) {
    // let tokenJson = util.getToken();
    // if (tokenJson == null) {
    //   return Promise.reject({ code: "TOKEN_MISSING" });
    // }
    var config = {
      headers: {
        // Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/queryLogistics`, expressRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  deliveredLogistics(logisticsId, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    var param = { logisticsId: logisticsId };
    param = util.parseParams(param);
    return axios
      .get(`/api/vendor/deliveredLogistics?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  batchDeliveredLogistics(logisticsIds, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/vendor/batchDeliveredLogistics`, logisticsIds, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  selfActivityInstallation(activityInstallationSelfRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(
        `/api/vendor/selfActivityInstallation`,
        activityInstallationSelfRO,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
export default scheduleRequest;
