import { defineComponent, reactive, ref, onMounted, onUnmounted, computed, getCurrentInstance, inject } from "vue";
import Tailoring from "../components/Tailoring.vue";
import SvgIcon from "../components/SvgIcon.vue";
import { useRouter } from "vue-router";
import permission from "@/util/permission";
import util from "@/util/util";
import { useI18n } from "vue-i18n";

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default defineComponent({
  name: "UserModify",
  components: {
    SvgIcon,
    Tailoring
  },
  setup() {
    const {
      t,
      locale
    } = useI18n(); // 获取 locale 引用
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    const mobileForm = ref(null);
    const emailForm = ref(null);
    const passwordForm = ref(null);
    const lastNameInput = ref(null);
    const firstNameInput = ref(null);
    const emailInput = ref(null);
    const addressInput = ref(null);
    const mobileInput = ref(null);
    const globalUser = inject("globalUser");
    const userData = reactive({
      userOrganization: {},
      userModifyRo: {
        id: null,
        lastName: null,
        firstName: null,
        avatar: null,
        mobile: null,
        smsCode: null,
        email: null,
        emailCode: null,
        address: null,
        password: null,
        oldPassword: null
      },
      mobileFormRules: {
        mobile: [{
          required: true,
          message: computed(() => t("userModify.ruleEnterMobile")),
          trigger: "blur"
        }, {
          min: 11,
          max: 11,
          message: computed(() => t("userModify.ruleValidMobile")),
          trigger: "blur"
        }],
        smsCode: [{
          required: true,
          message: computed(() => t("userModify.ruleEnterCode")),
          trigger: "blur"
        }, {
          min: 6,
          max: 6,
          message: computed(() => t("userModify.ruleValidCode")),
          trigger: "blur"
        }]
      },
      mobileFormData: {
        mobile: null,
        smsCode: null
      },
      mobileDialogVisible: false,
      emailFormRules: {
        email: [{
          required: true,
          message: computed(() => t("userModify.ruleEnterEmail")),
          trigger: "blur"
        }, {
          pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
          message: computed(() => t("userModify.ruleValidEmail")),
          trigger: "blur"
        }],
        emailCode: [{
          required: true,
          message: computed(() => t("userModify.ruleEnterCode")),
          trigger: "blur"
        }, {
          min: 6,
          max: 6,
          message: computed(() => t("userModify.ruleValidCode")),
          trigger: "blur"
        }]
      },
      emailFormData: {
        email: null,
        emailCode: null
      },
      emailDialogVisible: false,
      lastNameEdit: false,
      firstNameEdit: false,
      addressEdit: false,
      emailEdit: false,
      avatarDialogVisible: false,
      passwordFormRules: {
        oldPassword: [{
          required: true,
          message: computed(() => t("userModify.ruleEnterPassword")),
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: computed(() => t("userModify.ruleEnterPassword")),
          trigger: "blur"
        }, {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{8,}$/,
          message: computed(() => t("userModify.rulePasswordStrength")),
          trigger: "blur"
        }, {
          validator: (rule, value, callback) => {
            if (userData.passwordFormData.passwordAgain && userData.passwordFormData.passwordAgain !== userData.passwordFormData.password) {
              callback(new Error(t("userModify.rulePasswordMismatch")));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }],
        passwordAgain: [{
          required: true,
          message: computed(() => t("userModify.ruleReEnterPassword")),
          trigger: "blur"
        }, {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{8,}$/,
          message: computed(() => t("userModify.rulePasswordStrength")),
          trigger: "blur"
        }, {
          validator: (rule, value, callback) => {
            if (userData.passwordFormData.password && userData.passwordFormData.password !== userData.passwordFormData.passwordAgain) {
              callback(new Error(t("userModify.rulePasswordMismatch")));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }]
      },
      passwordFormData: {
        oldPassword: null,
        password: null,
        passwordAgain: null
      },
      passwordDialogVisible: false
    });
    const contenTab = ref(1);
    const getUserOrganization = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.userRequest.getUserOrganization(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t("userModify.fail"),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    onMounted(async () => {
      let userOrganization = await getUserOrganization();
      userData.userOrganization = userOrganization;
      userData.userModifyRo = {
        id: userOrganization.id,
        lastName: userOrganization.lastName,
        firstName: userOrganization.firstName,
        avatar: userOrganization.avatar,
        mobile: userOrganization.mobile,
        smsCode: null,
        email: userOrganization.email,
        address: userOrganization.address,
        password: null,
        oldPassword: null
      };
    });
    const editLastNameClick = () => {
      userData.lastNameEdit = true;
      lastNameInput.value.focus();
    };
    const editFirstNameClick = () => {
      userData.firstNameEdit = true;
      firstNameInput.value.focus();
    };
    const editEmailClick = () => {
      emailForm.value.resetFields();
      userData.emailDialogVisible = true;
      emailInput.value.focus();
    };
    const editAddressClick = () => {
      userData.addressEdit = true;
      addressInput.value.focus();
    };
    const imgUrlbase64 = code => {
      userData.userModifyRo.avatar = code;
      userData.avatarDialogVisible = false;
    };
    const editMobileClick = () => {
      mobileForm.value.resetFields();
      userData.mobileDialogVisible = true;
      mobileInput.value.focus();
    };
    const editPasswordClick = () => {
      passwordForm.value.resetFields();
      userData.passwordDialogVisible = true;
    };
    const smsModifyControl = reactive({
      smsCooldownSecond: 0,
      smsDisabled: false,
      smsCooldownHandle: null,
      smsButtonText: computed(() => smsModifyControl.smsCooldownSecond > 0 ? `${smsModifyControl.smsCooldownSecond}s` : t("userModify.sendCode"))
    });
    const smsModifyCooldown = () => {
      if (smsModifyControl.smsDisabled) return;
      smsModifyControl.smsDisabled = true;
      smsModifyControl.smsCooldownSecond = 60; // 设置倒计时时长

      if (smsModifyControl.smsCooldownHandle) clearInterval(smsModifyControl.smsCooldownHandle);
      smsModifyControl.smsCooldownHandle = setInterval(() => {
        smsModifyControl.smsCooldownSecond--;
        if (smsModifyControl.smsCooldownSecond <= 0) {
          clearInterval(smsModifyControl.smsCooldownHandle);
          smsModifyControl.smsDisabled = false;
        }
      }, 1000);
    };
    const onSendModifySms = () => {
      var patt = /^1[3-9]\d{9}$/;
      if (patt.test(userData.mobileFormData.mobile) == false) {
        ElNotification({
          title: t("userModify.fail"),
          message: t("userModify.validMobile2"),
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendRegisterVerificationCode(userData.mobileFormData.mobile).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201101") {
            ElNotification({
              title: t("userModify.success"),
              message: t("userModify.codeSentMobileSuccess"),
              type: "success",
              duration: 3000
            });
            smsModifyCooldown();
          } else {
            ElNotification({
              title: t("userModify.fail"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t("userModify.fail"),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: t("userModify.fail"),
          message: t("userModify.codeSentMobileFail"),
          type: "error",
          duration: 6000
        });
      });
    };
    const emailModifyControl = reactive({
      emailCooldownSecond: 0,
      emailDisabled: false,
      emailCooldownHandle: null,
      emailButtonText: computed(() => emailModifyControl.emailCooldownSecond > 0 ? `${emailModifyControl.emailCooldownSecond}s` : t("userModify.sendCode"))
    });
    const emailModifyCooldown = () => {
      if (emailModifyControl.emailDisabled) return;
      emailModifyControl.emailDisabled = true;
      emailModifyControl.emailCooldownSecond = 60; // 设置倒计时时长

      if (emailModifyControl.emailCooldownHandle) clearInterval(emailModifyControl.emailCooldownHandle);
      emailModifyControl.emailCooldownHandle = setInterval(() => {
        emailModifyControl.emailCooldownSecond--;
        if (emailModifyControl.emailCooldownSecond <= 0) {
          clearInterval(emailModifyControl.emailCooldownHandle);
          emailModifyControl.emailDisabled = false;
        }
      }, 1000);
    };
    const onSendModifyEmail = () => {
      var patt = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (patt.test(userData.emailFormData.email) == false) {
        ElNotification({
          title: t("userModify.fail"),
          message: t("userModify.validEmail2"),
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendRegisterEmailCode(userData.emailFormData.email).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201105") {
            ElNotification({
              title: t("userModify.success"),
              message: t("userModify.codeSentEmailSuccess"),
              type: "success",
              duration: 3000
            });
            emailModifyCooldown();
          } else {
            ElNotification({
              title: t("userModify.fail"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t("userModify.fail"),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: t("userModify.fail"),
          message: t("userModify.codeSentMobileFail"),
          type: "error",
          duration: 6000
        });
      });
    };
    const confirmSaveMobile = () => {
      mobileForm.value.validateFields().then(() => {
        let changeUserMobileRO = {
          mobile: userData.mobileFormData.mobile,
          smsCode: userData.mobileFormData.smsCode
        };
        changeUserMobile(changeUserMobileRO);
      }).catch(errors => {
        // 处理表单验证错误
        console.error(errors);
      });
    };
    const confirmSaveEmail = () => {
      emailForm.value.validateFields().then(() => {
        let changeUserEmailRO = {
          email: userData.emailFormData.email,
          emailCode: userData.emailFormData.emailCode
        };
        changeUserEmail(changeUserEmailRO);
      }).catch(errors => {
        // 处理表单验证错误
        console.error(errors);
      });
    };
    const confirmSavePassword = () => {
      passwordForm.value.validateFields().then(() => {
        let changeUserPasswordRO = {
          password: userData.passwordFormData.password,
          oldPassword: userData.passwordFormData.oldPassword
        };
        changeUserPassword(changeUserPasswordRO);
      }).catch(errors => {
        // 处理表单验证错误
        console.error(errors);
      });
    };
    const confirmSaveUser = () => {
      if (userData.userOrganization.lastName == userData.userModifyRo.lastName && userData.userOrganization.firstName == userData.userModifyRo.firstName && userData.userOrganization.avatar == userData.userModifyRo.avatar && userData.userOrganization.mobile == userData.userModifyRo.mobile && userData.userOrganization.email == userData.userModifyRo.email && userData.userOrganization.address == userData.userModifyRo.address && userData.userModifyRo.oldPassword == null) {
        ElNotification({
          title: t("userModify.fail"),
          message: t("userModify.noChange"),
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.userRequest.modifyUser(userData.userModifyRo).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202019") {
            let userOrganization = res.result.data;
            userData.userOrganization = userOrganization;
            userData.userModifyRo = {
              id: userOrganization.id,
              lastName: userOrganization.lastName,
              firstName: userOrganization.firstName,
              avatar: userOrganization.avatar,
              mobile: userOrganization.mobile,
              smsCode: null,
              email: userOrganization.email,
              emailCode: null,
              address: userOrganization.address,
              password: null,
              oldPassword: null
            };
            let userInfoData = util.getUserInfo();
            userInfoData.lastName = userOrganization.lastName;
            userInfoData.firstName = userOrganization.firstName;
            userInfoData.avatar = userOrganization.avatar;
            userInfoData.mobile = userOrganization.mobile;
            userInfoData.email = userOrganization.email;
            userInfoData.managerId = userOrganization.managerId;
            userInfoData.type = userOrganization.type;
            window.localStorage.setItem("pnp_user_info", JSON.stringify(userInfoData));
            globalUser.avatar = userInfoData.avatar;
            globalUser.email = userInfoData.email;
            globalUser.firstName = userInfoData.firstName;
            globalUser.lastName = userInfoData.lastName;
            globalUser.managerId = userInfoData.managerId;
            globalUser.type = userInfoData.type;
            ElNotification({
              title: t("userModify.success"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: t("userModify.fail"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t("userModify.fail"),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: t("userModify.fail"),
          message: t("userModify.editFail"),
          type: "error",
          duration: 6000
        });
      });
    };
    const cancelSaveUser = () => {
      router.go(-1);
    };
    const changeUserMobile = changeUserMobileRO => {
      proxy.$api.userRequest.changeUserMobile(changeUserMobileRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202024") {
            userData.userModifyRo.email = userData.emailFormData.email;
            userData.userModifyRo.emailCode = userData.emailFormData.emailCode;
            userData.emailFormData.email = null;
            userData.emailFormData.emailCode = null;
            userData.mobileDialogVisible = false;
            ElNotification({
              title: t("userModify.success"),
              message: t("userModify.editSuccess"),
              type: "success",
              duration: 6000
            });
          } else {
            ElNotification({
              title: t("userModify.fail"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t("userModify.fail"),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: t("userModify.fail"),
          message: t("userModify.verifyFail"),
          type: "error",
          duration: 6000
        });
      });
    };
    const changeUserEmail = changeUserEmailRO => {
      proxy.$api.userRequest.changeUserEmail(changeUserEmailRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202026") {
            userData.emailDialogVisible = false;
            userData.userModifyRo.email = userData.emailFormData.email;
            userData.userModifyRo.emailCode = userData.emailFormData.emailCode;
            userData.emailFormData.email = null;
            userData.emailFormData.emailCode = null;
            ElNotification({
              title: t("userModify.success"),
              message: t("userModify.editSuccess"),
              type: "success",
              duration: 6000
            });
          } else {
            ElNotification({
              title: t("userModify.fail"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t("userModify.fail"),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: t("userModify.fail"),
          message: t("userModify.verifyFail"),
          type: "error",
          duration: 6000
        });
      });
    };
    const changeUserPassword = changeUserPasswordRO => {
      proxy.$api.userRequest.changeUserPassword(changeUserPasswordRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202028") {
            userData.userModifyRo.oldPassword = userData.passwordFormData.oldPassword;
            userData.userModifyRo.password = userData.passwordFormData.password;
            userData.passwordFormData.oldPassword = null;
            userData.passwordFormData.password = null;
            userData.passwordFormData.passwordAgain = null;
            userData.passwordDialogVisible = false;
            ElNotification({
              title: t("userModify.success"),
              message: t("userModify.editSuccess"),
              type: "success",
              duration: 6000
            });
          } else {
            ElNotification({
              title: t("userModify.fail"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t("userModify.fail"),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: t("userModify.fail"),
          message: t("userModify.verifyFail"),
          type: "error",
          duration: 6000
        });
      });
    };
    return {
      lastNameInput,
      firstNameInput,
      emailInput,
      addressInput,
      mobileInput,
      mobileForm,
      emailForm,
      passwordForm,
      userData,
      permission,
      editLastNameClick,
      editFirstNameClick,
      editEmailClick,
      editAddressClick,
      editMobileClick,
      editPasswordClick,
      imgUrlbase64,
      smsModifyControl,
      onSendModifySms,
      emailModifyControl,
      onSendModifyEmail,
      confirmSaveMobile,
      confirmSaveEmail,
      confirmSavePassword,
      confirmSaveUser,
      cancelSaveUser,
      //2.0
      contenTab
    };
  }
});