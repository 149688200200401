import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import "core-js/modules/es.array.push.js";
import Tailoring from "../components/Tailoring.vue";
import { reactive, ref, onMounted, getCurrentInstance, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import util from "@/util/util";
import cookies from "js-cookie";
import { Close } from "@element-plus/icons-vue";
import { DownCircleOutlined, LeftCircleOutlined } from "@ant-design/icons-vue";
import { useI18n } from "vue-i18n";

import { ElNotification, ElMessage, ElMessageBox } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
import 'element-plus/es/components/message/style/css';
import 'element-plus/es/components/message-box/style/css';
export default {
  name: "RegisterLogin",
  components: {
    Tailoring,
    Close,
    DownCircleOutlined,
    LeftCircleOutlined
  },
  setup() {
    const {
      t,
      locale
    } = useI18n(); // 获取 locale 引用
    const changeLanguage = () => {
      locale.value = locale.value === "zh" ? "en" : "zh";
      localStorage.setItem("app-locale", locale.value); // 保存用户选择的语言
    };

    const route = useRoute();
    const redirect = route.query.redirect;
    console.log("redirect:" + redirect);
    window.localStorage.removeItem("union_token");
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    const loginBox = ref(null);
    const control = reactive({
      loginTab: 0,
      loginType: 0,
      avatardialogVisible: false,
      paneVisable: true,
      resetPasswordStep: 0
    });
    const cnenBtn = ref(0);
    const vendorProps = {
      value: "vendorId",
      label: "vendorName"
    };
    const storeProps = {
      value: "storeId",
      label: "storeName"
    };
    const brandProps = {
      value: "organizationId",
      label: "organizationName",
      children: "children"
    };
    const userTypeData = [{
      label: "品牌",
      value: 1
    }, {
      label: "供应商",
      value: 2
    }, {
      label: "店铺",
      value: 3
    }];
    const storeData = reactive([]);
    const vendorData = reactive([]);
    const organizationData = reactive([]);
    const usernameLoginForm = ref(null);
    const mobileLoginForm = ref(null);
    const resetPasswordForm = ref(null);
    const resetPasswordNextForm = ref(null);
    const mobileCheckForm = ref(null);
    const loginFormData = reactive({
      mobile: "",
      username: "",
      password: "",
      remember: false
    });
    const loginFormRules = computed(() => ({
      username: [{
        required: true,
        message: t("login.enterUsername"),
        trigger: "blur"
      }],
      password: [{
        required: true,
        message: t("login.enterPassword"),
        trigger: "blur"
      }],
      mobile: [{
        required: true,
        message: t("login.enterMobile"),
        trigger: "blur"
      }, {
        pattern: /^1[3-9]\d{9}$/,
        message: t("login.validMobile"),
        trigger: "blur"
      }],
      smsCode: [{
        required: true,
        message: t("login.enterCode"),
        trigger: "blur"
      }, {
        min: 6,
        max: 6,
        message: t("login.validCode"),
        trigger: "blur"
      }]
    }));
    const smsControl = reactive({
      smsCooldownSecond: 0,
      smsDisabled: false,
      smsCooldownHandle: null,
      smsButtonText: computed(() => smsControl.smsCooldownSecond > 0 ? `${smsControl.smsCooldownSecond}s` : t("login.sendCode"))
    });
    const smsCooldown = () => {
      if (smsControl.smsDisabled) return;
      smsControl.smsDisabled = true;
      smsControl.smsCooldownSecond = 60; // 设置倒计时时长

      if (smsControl.smsCooldownHandle) clearInterval(smsControl.smsCooldownHandle);
      smsControl.smsCooldownHandle = setInterval(() => {
        smsControl.smsCooldownSecond--;
        if (smsControl.smsCooldownSecond <= 0) {
          clearInterval(smsControl.smsCooldownHandle);
          smsControl.smsDisabled = false;
        }
      }, 1000);
    };

    // const smsCooldown = () => {
    //   smsControl.smsCooldownHandle = setInterval(() => {
    //     smsControl.smsCooldownSecond--;
    //     if (smsControl.smsCooldownSecond <= 0) {
    //       clearInterval(smsControl.smsCooldownHandle);
    //       smsControl.smsDisabled = false;
    //     }
    //     // if (smsControl.smsCooldownSecond === 0) {
    //     //   smsControl.smsDisabled = false;
    //     //   clearInterval(smsControl.smsCooldownHandle);
    //     //   // 直接覆盖计算属性（不推荐但能运行）
    //     //   smsControl.smsButtonText = computed(() => t("login.sendCode"));
    //     // } else {
    //     //   // 强制拼接秒数（需保证语言包有此格式）
    //     //   smsControl.smsButtonText = computed(
    //     //     () => `${smsControl.smsCooldownSecond}s`
    //     //   );
    //     // }
    //   }, 1000);
    // };

    const getStore = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreAll(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          ElNotification({
            title: t("login.fail"),
            message: error,
            type: "error",
            duration: 3000
          });
          resolve(null);
        });
      });
    };
    const getVendor = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorAll(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          ElNotification({
            title: t("login.fail"),
            message: error,
            type: "error",
            duration: 3000
          });
          resolve(null);
        });
      });
    };
    const getOrganizationBrand = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.organizationRequest.getOrganizationBrand(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          ElNotification({
            title: t("login.fail"),
            message: error,
            type: "error",
            duration: 3000
          });
          resolve(null);
        });
      });
    };

    // const onLoginSubmit = (e, loginType) => {
    //   e.preventDefault();
    //   let loginForm = usernameLoginForm;
    //   let params = {
    //     username: loginFormData.username,
    //     password: loginFormData.password,
    //     grant_type: "password",
    //     loginType: 1,
    //   };
    //   if (loginType == 2) {
    //     loginForm = mobileLoginForm;
    //     params.username = loginFormData.mobile;
    //     params.password = loginFormData.smsCode;
    //     params.loginType = 2;
    //     loginFormData.remember = false;
    //   }
    //   loginForm.value.validate((valid) => {
    //     if (valid) {
    //       onLogin(params, loginFormData.remember);
    //     }
    //   });
    // };
    const onLoginSubmit = async (e, loginType) => {
      e.preventDefault();
      let loginForm = usernameLoginForm;
      let params = {
        username: loginFormData.username,
        password: loginFormData.password,
        grant_type: "password",
        loginType: 1
      };
      if (loginType == 2) {
        loginForm = mobileLoginForm;
        params.username = loginFormData.mobile;
        params.password = loginFormData.smsCode;
        params.loginType = 2;
        loginFormData.remember = false;
      }
      try {
        await loginForm.value.validate();
        onLogin(params, loginFormData.remember);
      } catch (error) {
        console.log("Validation failed:", error);
      }
    };
    const onLogin = (params, remember) => {
      proxy.$api.userRequest.login(params).then(res => {
        console.log(res);
        res.avatar = null;
        if (remember === true) {
          sessionStorage.removeItem("token");
          cookies.set("token", JSON.stringify(res), {
            expires: 30
          });
        } else {
          cookies.remove("token");
          sessionStorage.setItem("token", JSON.stringify(res));
        }
        proxy.$api.userRequest.getUserPermission().then(res => {
          if (res.code === "200000") {
            if (res.result.dataCode === "202016") {
              let userInfo = res.result.data;
              window.localStorage.setItem("pnp_user_info", JSON.stringify(userInfo));
              if (redirect != null && redirect != "" && (redirect.includes("report-interaction") || redirect.includes("repair-manage"))) {
                let url = new URL(decodeURIComponent(redirect));
                let path = url.pathname + url.search + url.hash;
                router.push({
                  path: path
                });
              } else {
                router.push({
                  path: util.getPermissionRoutePath()
                });
              }
            }
          } else {
            ElMessage.error("登录失败");
          }
        }).catch(error => {
          ElMessage.error("登录失败");
        });
      }).catch(error => {
        if (error.error == "unauthorized") {
          ElMessage.error(error.error_description);
        } else {
          ElMessage.error("登录失败");
        }
      });
    };
    const registerForm = ref(null);
    const registerFormFirst = ref(null);
    const registerFormData = reactive({
      password: null,
      passwordAgain: null,
      lastName: null,
      firstName: null,
      avatar: "https://avatars2.githubusercontent.com/u/15681693?s=460&v=4",
      mobile: null,
      smsCode: null,
      email: null,
      emailCode: null,
      address: null,
      type: 1,
      managerId: null,
      uuid: null
    });
    const registerFormRules = computed(() => ({
      lastName: [{
        required: true,
        message: t("login.enterLastName"),
        trigger: "blur"
      }],
      firstName: [{
        required: true,
        message: t("login.enterFirstName"),
        trigger: "blur"
      }],
      type: [{
        required: true,
        message: t("login.pleaseSelectUserType"),
        trigger: "blur"
      }],
      managerId: [{
        required: true,
        message: t("login.pleaseSelect"),
        trigger: "blur"
      }]
    }));
    const registerFormNextRules = computed(() => ({
      mobile: [{
        required: true,
        message: t("login.enterMobile"),
        trigger: "blur"
      }, {
        min: 11,
        max: 11,
        message: t("login.validMobile"),
        trigger: "blur"
      }],
      smsCode: [{
        required: true,
        message: t("login.enterCode"),
        trigger: "blur"
      }, {
        min: 6,
        max: 6,
        message: t("login.validCode"),
        trigger: "blur"
      }],
      email: [{
        required: true,
        message: t("login.enterEmail"),
        trigger: "blur"
      }, {
        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        message: t("login.validEmail"),
        trigger: "blur"
      }],
      emailCode: [{
        required: true,
        message: t("login.enterCode"),
        trigger: "blur"
      }, {
        min: 6,
        max: 6,
        message: t("login.validCode"),
        trigger: "blur"
      }],
      password: [{
        required: true,
        message: t("login.enterPassword"),
        trigger: "blur"
      }, {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d!@#$%^&*()\-_=\+[\]{}|;:'",<.>\/?]{8,}$/,
        message: t("login.passwordStrength"),
        trigger: "blur"
      }, {
        validator: (rule, value) => {
          return new Promise((resolve, reject) => {
            if (registerFormData.passwordAgain && registerFormData.passwordAgain !== registerFormData.password) {
              reject(new Error(t("login.passwordMismatch")));
            } else {
              resolve();
            }
          });
        },
        trigger: "blur"
      }],
      passwordAgain: [{
        required: true,
        message: t("login.confirmPassword"),
        trigger: "blur"
      }, {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d!@#$%^&*()\-_=\+[\]{}|;:'",<.>\/?]{8,}$/,
        message: t("login.passwordStrength"),
        trigger: "blur"
      }, {
        validator: (rule, value) => {
          return new Promise((resolve, reject) => {
            if (registerFormData.password && registerFormData.password !== registerFormData.passwordAgain) {
              reject(new Error(t("login.passwordMismatch")));
            } else {
              resolve();
            }
          });
        },
        trigger: "blur"
      }]
    }));
    const onRegisterNext = async e => {
      e.preventDefault();
      try {
        await registerFormFirst.value.validate();
        control.loginTab = 2;
      } catch (error) {
        console.log("Validation failed:", error);
      }
    };
    const onRegisterSubmit = async e => {
      e.preventDefault();
      try {
        const valid = await registerForm.value.validate();
        if (valid) {
          if (registerFormData.passwordAgain != registerFormData.password) {
            ElNotification({
              title: t("login.fail"),
              message: t("login.passwordMismatch"),
              type: "error",
              duration: 3000
            });
            return;
          }
          if (registerFormData.type === 5) {
            registerFormData.managerId = 356;
          }
          const res = await proxy.$api.userRequest.registerUser(registerFormData);
          if (res.code === "200000") {
            if (res.result.dataCode === "202009") {
              loginClick();
              ElMessageBox.alert(t("login.registerSuccess"), t("login.prompt"), {
                confirmButtonText: t("login.confirm"),
                type: "success"
              });
            } else {
              ElNotification({
                title: t("login.fail"),
                message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
                type: "error",
                duration: 3000
              });
            }
          } else {
            ElNotification({
              title: t("login.fail"),
              message: res.msg,
              type: "error",
              duration: 3000
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    onMounted(async () => {
      window.addEventListener("resize", util.debounce(windowResize, 0));
      windowResize();
      let store = await getStore();
      if (store == null) {
        return;
      }
      storeData.push(...store);
      let vendor = await getVendor();
      if (vendor == null) {
        return;
      }
      vendorData.push(...vendor);
      let organizationList = await getOrganizationBrand();
      if (organizationList == null) {
        return;
      }
      const organizationDept = {};
      organizationData.length = 0;
      for (var organization of organizationList) {
        if (organization.depth == 1) {
          organization.disabled = true;
          organizationDept[organization.path + organization.organizationId] = organization;
          organizationData.push(organization);
        } else {
          if (organizationDept[organization.path].children == null) {
            organizationDept[organization.path].children = [];
          }
          organizationDept[organization.path + "/" + organization.organizationId] = organization;
          organizationDept[organization.path].children.push(organization);
        }
      }
    });
    const windowResize = () => {
      var width = window.innerWidth;
      let mainBody = document.getElementById("main");
      if (width > 400) {
        if (loginBox.vaue && loginBox.vaue.style) {
          loginBox.value.style.minHeight = "unset";
        }
      } else {
        if (loginBox.vaue && loginBox.vaue.style) {
          loginBox.value.style.minHeight = mainBody.style.height;
        }
      }
    };
    function imgUrlbase64(code) {
      // console.log(code)
      registerFormData.avatar = code;
      control.avatardialogVisible = false;
    }
    const loginClick = () => {
      control.loginTab = 0;
      control.paneVisable = true;
      if (usernameLoginForm.value) {
        usernameLoginForm.value.resetFields();
      }
      if (mobileLoginForm.value) {
        mobileLoginForm.value.resetFields();
      }
      if (registerForm.value) {
        registerForm.value.resetFields();
      }
    };
    const createImageWithText = (text, bgColor, width, height) => {
      // 创建canvas元素
      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      // 获取绘图上下文
      var ctx = canvas.getContext("2d");

      // 设置背景颜色
      ctx.fillStyle = bgColor;
      ctx.fillRect(0, 0, width, height);

      // 设置字体样式并居中显示文字
      ctx.fillStyle = "white"; // 文字颜色
      ctx.font = "bold 80px Arial"; // 修改为适当的字体和大小
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(text, width / 2, height / 2);

      // 将canvas内容转为Base64编码的图片
      return canvas.toDataURL();
    };

    // 使用函数创建图像并转为Base64
    const firstNameChange = value => {
      console.log(value.data);
      if (value.data != null && value.data.length > 0) {
        var base64Image = createImageWithText(value.data[0], "#55ac68", 150, 150);
        console.log("1" + base64Image);
        registerFormData.avatar = base64Image;
      } else {
        registerFormData.avatar = null;
      }
    };
    const onSendSms = () => {
      var patt = /^1[3-9]\d{9}$/;
      if (patt.test(loginFormData.mobile) == false) {
        ElNotification({
          title: t("login.fail"),
          message: t("login.validMobile"),
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendLoginVerificationCode(loginFormData.mobile).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201101") {
            debugger;
            ElNotification({
              title: t("login.success"),
              message: t("login.sendCodeSuccess"),
              type: "success",
              duration: 3000
            });
            smsCooldown();
          } else {
            ElNotification({
              title: t("login.fail"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t("login.fail"),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: t("login.fail"),
          message: t("login.sendCodeFail"),
          type: "error",
          duration: 6000
        });
      });
    };
    const smsRegisterControl = reactive({
      smsCooldownSecond: 0,
      smsDisabled: false,
      smsCooldownHandle: null,
      smsButtonText: computed(() => smsRegisterControl.smsCooldownSecond > 0 ? `${smsRegisterControl.smsCooldownSecond}s` : t("login.sendCode"))
    });
    const smsRegisterCooldown = () => {
      if (smsRegisterControl.smsDisabled) return;
      smsRegisterControl.smsDisabled = true;
      smsRegisterControl.smsCooldownSecond = 60; // 设置倒计时时长

      if (smsRegisterControl.smsCooldownHandle) clearInterval(smsRegisterControl.smsCooldownHandle);
      smsRegisterControl.smsCooldownHandle = setInterval(() => {
        smsRegisterControl.smsCooldownSecond--;
        if (smsRegisterControl.smsCooldownSecond <= 0) {
          clearInterval(smsRegisterControl.smsCooldownHandle);
          smsRegisterControl.smsDisabled = false;
        }
      }, 1000);
    };
    const onSendRegisterSms = () => {
      var patt = /^1[3-9]\d{9}$/;
      if (patt.test(registerFormData.mobile) == false) {
        ElNotification({
          title: t("login.fail"),
          message: t("login.validMobile"),
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendRegisterVerificationCode(registerFormData.mobile).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201101") {
            ElNotification({
              title: t("login.success"),
              message: t("login.sendCodeSuccess"),
              type: "success",
              duration: 3000
            });
            smsRegisterCooldown();
          } else {
            ElNotification({
              title: t("login.fail"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t("login.fail"),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: t("login.fail"),
          message: t("login.sendCodeFail"),
          type: "error",
          duration: 6000
        });
      });
    };
    const emailRegisterControl = reactive({
      smsCooldownSecond: 0,
      smsDisabled: false,
      smsCooldownHandle: null,
      smsButtonText: computed(() => emailRegisterControl.smsCooldownSecond > 0 ? `${emailRegisterControl.smsCooldownSecond}s` : t("login.sendCode"))
    });
    const emailRegisterCooldown = () => {
      if (emailRegisterControl.smsDisabled) return;
      emailRegisterControl.smsDisabled = true;
      emailRegisterControl.smsCooldownSecond = 60; // 设置倒计时时长

      if (emailRegisterControl.smsCooldownHandle) clearInterval(emailRegisterControl.smsCooldownHandle);
      emailRegisterControl.smsCooldownHandle = setInterval(() => {
        emailRegisterControl.smsCooldownSecond--;
        if (emailRegisterControl.smsCooldownSecond <= 0) {
          clearInterval(emailRegisterControl.smsCooldownHandle);
          emailRegisterControl.smsDisabled = false;
        }
      }, 1000);
    };
    const onSendRegisterEmail = () => {
      var patt = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (patt.test(registerFormData.email) == false) {
        ElNotification({
          title: t("login.fail"),
          message: t("login.validEmail"),
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendRegisterEmailCode(registerFormData.email).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201105") {
            ElNotification({
              title: t("login.success"),
              message: t("login.sendEmailCodeSuccess"),
              type: "success",
              duration: 3000
            });
            emailRegisterCooldown();
          } else {
            ElNotification({
              title: t("login.fail"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t("login.fail"),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: t("login.fail"),
          message: t("login.sendCodeFail"),
          type: "error",
          duration: 6000
        });
      });
    };
    const resetPasswordFormData = reactive({
      email: "",
      password: "",
      passwordAgain: "",
      emailCode: ""
    });
    const resetPasswordFormRules = computed(() => ({
      email: [{
        required: true,
        message: t("login.enterEmail"),
        trigger: "blur"
      }, {
        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        message: t("login.validEmail"),
        trigger: "blur"
      }],
      emailCode: [{
        required: true,
        message: t("login.enterCode"),
        trigger: "none"
      }, {
        min: 6,
        max: 6,
        message: t("login.validCode"),
        trigger: "none"
      }],
      password: [{
        required: true,
        message: t("login.enterPassword"),
        trigger: "blur"
      }, {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d!@#$%^&*()\-_=\+[\]{}|;:'",<.>\/?]{8,}$/,
        message: t("login.passwordStrength"),
        trigger: "blur"
      }, {
        validator: (rule, value) => {
          return new Promise((resolve, reject) => {
            if (resetPasswordFormData.passwordAgain && resetPasswordFormData.passwordAgain !== resetPasswordFormData.password) {
              reject(new Error(t("login.passwordMismatch")));
            } else {
              resolve();
            }
          });
        },
        trigger: "blur"
      }],
      passwordAgain: [{
        required: true,
        message: t("login.confirmPassword"),
        trigger: "blur"
      }, {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d!@#$%^&*()\-_=\+[\]{}|;:'",<.>\/?]{8,}$/,
        message: t("login.passwordStrength"),
        trigger: "blur"
      }, {
        validator: (rule, value) => {
          return new Promise((resolve, reject) => {
            if (resetPasswordFormData.password && resetPasswordFormData.password !== resetPasswordFormData.passwordAgain) {
              reject(new Error(t("login.passwordMismatch")));
            } else {
              resolve();
            }
          });
        },
        trigger: "blur"
      }]
    }));
    const resetPasswordControl = reactive({
      smsCooldownSecond: 0,
      smsDisabled: false,
      smsCooldownHandle: null,
      smsButtonText: computed(() => resetPasswordControl.smsCooldownSecond > 0 ? `${resetPasswordControl.smsCooldownSecond}s` : t("login.sendCode"))
    });
    const resetPasswordCooldown = () => {
      if (resetPasswordControl.smsDisabled) return;
      resetPasswordControl.smsDisabled = true;
      resetPasswordControl.smsCooldownSecond = 60; // 设置倒计时时长

      if (resetPasswordControl.smsCooldownHandle) clearInterval(resetPasswordControl.smsCooldownHandle);
      resetPasswordControl.smsCooldownHandle = setInterval(() => {
        resetPasswordControl.smsCooldownSecond--;
        if (resetPasswordControl.smsCooldownSecond <= 0) {
          clearInterval(resetPasswordControl.smsCooldownHandle);
          resetPasswordControl.smsDisabled = false;
        }
      }, 1000);
    };
    const onSendResetPassword = () => {
      var patt = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (patt.test(resetPasswordFormData.email) == false) {
        ElNotification({
          title: t("login.fail"),
          message: t("login.validEmail"),
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendResetPasswordEmailCode(resetPasswordFormData.email).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201112") {
            ElNotification({
              title: t("login.success"),
              message: t("login.sendEmailCodeSuccess"),
              type: "success",
              duration: 3000
            });
            resetPasswordCooldown();
          } else {
            ElNotification({
              title: t("login.fail"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t("login.fail"),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: t("login.fail"),
          message: t("login.sendCodeFail"),
          type: "error",
          duration: 6000
        });
      });
    };
    const onCheckResetPasswordNext = () => {
      var patt = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (patt.test(resetPasswordFormData.email) == false) {
        ElNotification({
          title: t("login.fail"),
          message: t("login.validEmail"),
          type: "error",
          duration: 6000
        });
        return;
      }
      resetPasswordForm.value.validate().then(valid => {
        if (valid) {
          return proxy.$api.authorizationRequest.checkResetPasswordEmailCode(resetPasswordFormData.email, resetPasswordFormData.emailCode);
        } else {
          throw new Error(t("login.verificationFail"));
        }
      }).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201114") {
            control.resetPasswordStep = 1;
          } else {
            ElNotification({
              title: t("login.fail"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t("login.fail"),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: t("login.fail"),
          message: error.message || t("login.sendCodeFail"),
          type: "error",
          duration: 6000
        });
      });
    };
    const onResetPassword = () => {
      var patt = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (patt.test(resetPasswordFormData.email) == false) {
        ElNotification({
          title: t("login.fail"),
          message: t("login.validEmail"),
          type: "error",
          duration: 6000
        });
        return;
      }
      resetPasswordNextForm.value.validate().then(valid => {
        if (valid) {
          let resetPasswordRO = {
            email: resetPasswordFormData.email,
            code: resetPasswordFormData.emailCode,
            password: resetPasswordFormData.password
          };
          return proxy.$api.authorizationRequest.resetPassword(resetPasswordRO);
        } else {
          throw new Error(t("login.verificationFail"));
        }
      }).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201117") {
            control.resetPasswordStep = 2;
          } else {
            ElNotification({
              title: t("login.fail"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t("login.fail"),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: t("login.fail"),
          message: error.message || t("login.sendCodeFail"),
          type: "error",
          duration: 6000
        });
      });
    };
    const mobileCheckFormData = reactive({
      uuid: "111",
      mobile: "",
      smsCode: null
    });
    const mobileCheckFormRules = computed(() => ({
      mobile: [{
        required: true,
        message: t("login.enterMobile"),
        trigger: "blur"
      }, {
        pattern: /^1[3-9]\d{9}$/,
        message: t("login.validMobile"),
        trigger: "blur"
      }],
      smsCode: [{
        required: true,
        message: t("login.enterCode"),
        trigger: "blur"
      }, {
        min: 6,
        max: 6,
        message: t("login.validCode"),
        trigger: "blur"
      }]
    }));
    const mobileCheckControl = reactive({
      smsCooldownSecond: 60,
      smsDisabled: false,
      smsButtonText: computed(() => smsControl.smsCooldownSecond > 0 ? `${smsControl.smsCooldownSecond}s` : t("login.sendCode")),
      message: "",
      buttonText: t("login.quickBind"),
      submitType: 1 //1快速绑定2注册账号
    });

    const mobileCheckCooldown = () => {
      if (mobileCheckControl.smsDisabled) return;
      mobileCheckControl.smsDisabled = true;
      mobileCheckControl.smsCooldownSecond = 60; // 设置倒计时时长

      if (mobileCheckControl.smsCooldownHandle) clearInterval(mobileCheckControl.smsCooldownHandle);
      mobileCheckControl.smsCooldownHandle = setInterval(() => {
        mobileCheckControl.smsCooldownSecond--;
        if (mobileCheckControl.smsCooldownSecond <= 0) {
          clearInterval(mobileCheckControl.smsCooldownHandle);
          mobileCheckControl.smsDisabled = false;
        }
      }, 1000);
    };
    const onSendMobileCheckSms = () => {
      var patt = /^1[3-9]\d{9}$/;
      if (patt.test(mobileCheckFormData.mobile) == false) {
        ElNotification({
          title: t("login.fail"),
          message: t("login.validMobile"),
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendWxVerificationCode(mobileCheckFormData.mobile).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201101") {
            ElNotification({
              title: t("login.success"),
              message: t("login.sendCodeSuccess"),
              type: "success",
              duration: 3000
            });
            mobileCheckCooldown();
          } else {
            ElNotification({
              title: t("login.fail"),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t("login.fail"),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: t("login.fail"),
          message: t("login.sendCodeFail"),
          type: "error",
          duration: 6000
        });
      });
    };
    const mobileInput = value => {
      if (value == null || value.length < 11) {
        return;
      }
      var patt = /^1[3-9]\d{9}$/;
      if (patt.test(value) == false) {
        mobileCheckControl.message = t("login.invalidMobile");
      } else {
        mobileCheckControl.message = "";
        proxy.$api.authorizationRequest.checkMobileState(value).then(res => {
          if (res.code === "200000") {
            if (res.result.dataCode === "201122") {
              mobileCheckControl.message = t("login.mobileWeChatBound");
            } else if (res.result.dataCode === "201121") {
              mobileCheckControl.buttonText = t("login.quickBind");
              mobileCheckControl.submitType = 1;
            } else {
              mobileCheckControl.buttonText = t("login.registerAccount");
              mobileCheckControl.submitType = 2;
            }
          } else {
            ElNotification({
              title: t("login.fail"),
              message: res.msg,
              type: "error",
              duration: 6000
            });
          }
        }).catch(error => {
          ElNotification({
            title: t("login.fail"),
            message: error,
            type: "error",
            duration: 6000
          });
        });
      }
    };
    const onWxBind = () => {
      var patt = /^1[3-9]\d{9}$/;
      if (patt.test(mobileCheckFormData.mobile) == false) {
        ElNotification({
          title: t("login.fail"),
          message: t("login.validMobile"),
          type: "error",
          duration: 6000
        });
        return;
      }
      mobileCheckForm.value.validate(valid => {
        if (valid) {
          if (mobileCheckControl.submitType === 1) {
            let bindWxRO = {
              mobile: mobileCheckFormData.mobile,
              code: mobileCheckFormData.smsCode,
              uuid: mobileCheckFormData.uuid
            };
            proxy.$api.authorizationRequest.bindWx(bindWxRO).then(res => {
              if (res.code === "200000") {
                if (res.result.dataCode === "201127") {
                  let params = {
                    username: mobileCheckFormData.mobile,
                    password: mobileCheckFormData.uuid,
                    grant_type: "password",
                    uuid: mobileCheckFormData.uuid,
                    loginType: 2
                  };
                  onLogin(params, false);
                } else {
                  ElNotification({
                    title: t("login.fail"),
                    message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
                    type: "error",
                    duration: 6000
                  });
                }
              } else {
                ElNotification({
                  title: t("login.fail"),
                  message: res.msg,
                  type: "error",
                  duration: 6000
                });
              }
            }).catch(error => {
              ElNotification({
                title: t("login.fail"),
                message: error,
                type: "error",
                duration: 6000
              });
            });
          } else {
            registerFormData.mobile = mobileCheckFormData.mobile;
            registerFormData.smsCode = mobileCheckFormData.smsCode;
            registerFormData.uuid = mobileCheckFormData.uuid;
            control.loginTab = 1;
            control.paneVisable = true;
          }
        }
      });
    };
    var intervalId = null;
    const onWxLogin = () => {
      // control.loginTab = 5;
      // return;
      window.localStorage.removeItem("union_token");
      if (intervalId != null) {
        clearInterval(intervalId);
      }
      intervalId = setInterval(function () {
        const unionToken = window.localStorage.getItem("union_token");
        console.log(unionToken);
        if (unionToken != null) {
          const unionTokenJson = JSON.parse(unionToken);
          if (unionTokenJson.mobile != null) {
            let params = {
              username: unionTokenJson.mobile,
              password: unionTokenJson.uuid,
              grant_type: "password",
              uuid: unionTokenJson.uuid,
              loginType: 2
            };
            onLogin(params, false);
          } else {
            control.loginTab = 5;
            mobileCheckFormData.uuid = unionTokenJson.uuid;
          }
          clearInterval(intervalId);
          intervalId = null;
        }
      }, 1000);
      var wx = new WxLogin({
        self_redirect: true,
        id: "login_container",
        appid: "wxd3b6efc7ed196d5e",
        scope: "snsapi_login",
        redirect_uri: "https://servicechannel.online/wx-check",
        state: "",
        style: "",
        href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC53YWl0aW5nLnBhbmVsQ29udGVudCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7Cn0KLmltcG93ZXJCb3ggLnFyY29kZS5saWdodEJvcmRlciB7CiAgd2lkdGg6IDE2OHB4Owp9Cg=="
      });
      control.loginTab = 4;
    };
    return {
      changeLanguage,
      loginBox,
      control,
      vendorProps,
      storeProps,
      brandProps,
      usernameLoginForm,
      mobileLoginForm,
      loginFormData,
      loginFormRules,
      registerFormFirst,
      registerForm,
      registerFormData,
      registerFormRules,
      registerFormNextRules,
      userTypeData,
      vendorData,
      storeData,
      organizationData,
      onSendSms,
      onSendRegisterSms,
      smsControl,
      smsRegisterControl,
      onLoginSubmit,
      onRegisterNext,
      onRegisterSubmit,
      imgUrlbase64,
      loginClick,
      firstNameChange,
      onSendRegisterEmail,
      emailRegisterControl,
      resetPasswordForm,
      resetPasswordFormData,
      resetPasswordFormRules,
      resetPasswordControl,
      onSendResetPassword,
      onCheckResetPasswordNext,
      resetPasswordNextForm,
      onResetPassword,
      mobileCheckForm,
      mobileCheckFormData,
      mobileCheckFormRules,
      mobileCheckControl,
      onSendMobileCheckSms,
      mobileInput,
      onWxBind,
      onWxLogin,
      cnenBtn
    };
  }
};